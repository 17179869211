import React from 'react';
import { User, Briefcase, GraduationCap, Code, Award, Mail, Phone, Linkedin, MapPin, ChevronRight } from 'lucide-react';

const ResumeWebsite = () => {
  return (
    <div className="min-h-screen font-sans text-gray-800 bg-gradient-to-br from-blue-500 via-purple-500 to-pink-500 p-4 sm:p-8">
      <div className="max-w-7xl mx-auto bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-3xl shadow-2xl overflow-hidden">
        <header className="relative h-64 sm:h-96 bg-gradient-to-r from-blue-600 to-purple-600 p-4 sm:p-8 flex flex-col justify-end">
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="relative z-10">
            <h1 className="text-4xl sm:text-6xl font-bold text-white mb-2 sm:mb-4">Zach Lowy</h1>
            <p className="text-xl sm:text-3xl text-gray-200 mb-4 sm:mb-6">Software Design & Engineering</p>
            <div className="flex flex-col sm:flex-row flex-wrap gap-2 sm:gap-6 text-gray-200 text-sm sm:text-base">
              <a href="mailto:zacharylowy1@gmail.com" className="flex items-center hover:text-white transition-colors duration-300">
                <Mail className="mr-2" size={16} /> zacharylowy1@gmail.com
              </a>
              <a href="tel:917-246-8889" className="flex items-center hover:text-white transition-colors duration-300">
                <Phone className="mr-2" size={16} /> 917-246-8889
              </a>
              <a href="https://linkedin.com/in/zach-lowy-25aa97225" className="flex items-center hover:text-white transition-colors duration-300" target="_blank" rel="noopener noreferrer">
                <Linkedin className="mr-2" size={16} /> LinkedIn
              </a>
              <span className="flex items-center">
                <MapPin className="mr-2" size={16} /> Five Towns, New York
              </span>
            </div>
          </div>
        </header>

        <main className="p-4 sm:p-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-8">
          <section className="col-span-1 sm:col-span-2 bg-white bg-opacity-20 rounded-xl p-4 sm:p-6 shadow-lg">
            <h2 className="text-2xl sm:text-3xl font-semibold mb-4 sm:mb-6 text-white flex items-center">
              <User className="mr-2 sm:mr-3" size={24} /> Professional Summary
            </h2>
            <p className="text-gray-200 leading-relaxed text-sm sm:text-base">
              Experienced web/ios software engineer and designer with a diverse background in software development, commercial real estate financing, and artificial intelligence. Proven track record of managing projects and developing numerous iOS and web applications. Career has evolved from managing a restaurant to becoming a CTO, reflecting adaptability and a broad skill set.
            </p>
          </section>

          <section className="bg-white bg-opacity-20 rounded-xl p-4 sm:p-6 shadow-lg">
            <h2 className="text-2xl sm:text-3xl font-semibold mb-4 sm:mb-6 text-white flex items-center">
              <Code className="mr-2 sm:mr-3" size={24} /> Skills
            </h2>
            <ul className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              {[                'Software Development',
                'Commercial Real Estate Financing',
                'Artificial Intelligence',
                'Advanced Computer Skills',
                'Microsoft 365',
                'Canva',
                'Figma',
                'Adobe Creative Suite'].map((skill, index) => (
                <li key={index} className="bg-gradient-to-r from-blue-400 to-indigo-500 text-white p-2 sm:p-3 rounded-lg text-center text-sm sm:text-base transform hover:scale-105 transition-transform duration-300 shadow-md">
                  {skill}
                </li>
              ))}
            </ul>
          </section>

          <section className="col-span-1 sm:col-span-2 lg:col-span-3 bg-white bg-opacity-20 rounded-xl p-4 sm:p-6 shadow-lg">
            <h2 className="text-2xl sm:text-3xl font-semibold mb-4 sm:mb-6 text-white flex items-center">
              <Briefcase className="mr-2 sm:mr-3" size={24} /> Work Experience
            </h2>
            <div className="space-y-4 sm:space-y-6">
              {[
                {
                  title: "Chief Technology Officer",
                  company: "Seventh Street Development, Brooklyn, NY",
                  duration: "1.5 years",
                  responsibilities: [
                    "Developed numerous iOS and web applications.",
                    "Oversaw technology strategy and implementation for real estate development projects.",
                    "Managed a team of developers and coordinated with stakeholders to deliver high-quality software solutions."
                  ]
                },
                {
                  title: "Commercial Mortgage Broker",
                  company: "SHB Group",
                  duration: "2.5 years",
                  responsibilities: [
                    "Handled sales and underwriting for commercial mortgages.",
                    "Developed financial models and conducted risk assessments.",
                    "Negotiated mortgage terms and closed deals efficiently."
                  ]
                },
                {
                  title: "Manager",
                  company: "Dougies Woodburne, Woodburne, NY",
                  duration: "",
                  responsibilities: [
                    "Managed restaurant operations and staff.",
                    "Ensured customer satisfaction and optimized operational efficiency."
                  ]
                }
              ].map((job, index) => (
                <div key={index} className="bg-white bg-opacity-10 p-3 sm:p-4 rounded-lg">
                  <h3 className="text-xl sm:text-2xl font-semibold text-white">{job.title}</h3>
                  <p className="text-gray-300 text-sm sm:text-base mb-2">{job.company} {job.duration && `| ${job.duration}`}</p>
                  <ul className="list-disc list-inside text-gray-200 text-sm sm:text-base">
                    {job.responsibilities.map((resp, idx) => (
                      <li key={idx}>{resp}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </section>

          <section className="col-span-1 sm:col-span-2 bg-white bg-opacity-20 rounded-xl p-4 sm:p-6 shadow-lg">
            <h2 className="text-2xl sm:text-3xl font-semibold mb-4 sm:mb-6 text-white flex items-center">
              <GraduationCap className="mr-2 sm:mr-3" size={24} /> Education
            </h2>
            <div className="bg-white bg-opacity-10 p-3 sm:p-4 rounded-lg">
              <p className="text-xl sm:text-2xl text-white">Bachelor's Degree in Liberal Arts and Sciences</p>
              <p className="text-gray-300 text-sm sm:text-base">Excelsior University</p>
            </div>
          </section>

          <section className="bg-white bg-opacity-20 rounded-xl p-4 sm:p-6 shadow-lg">
            <h2 className="text-2xl sm:text-3xl font-semibold mb-4 sm:mb-6 text-white flex items-center">
              <Award className="mr-2 sm:mr-3" size={24} /> Personal Hobbies
            </h2>
            <ul className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              {['Golf', 'Software Development', 'Video Games', 'Tennis', 'Pickleball'].map((hobby, index) => (
                <li key={index} className="bg-gradient-to-r from-green-400 to-blue-500 text-white p-2 sm:p-3 rounded-lg flex items-center justify-between text-sm sm:text-base transform hover:scale-105 transition-transform duration-300 shadow-md">
                  {hobby}
                  <ChevronRight size={16} />
                </li>
              ))}
            </ul>
          </section>
        </main>

        <footer className="text-center py-4 sm:py-6 text-gray-200 bg-gray-800 bg-opacity-50 text-sm sm:text-base">
          <p>&copy; 2024 Zach Lowy. All rights reserved.</p>
        </footer>
      </div>
    </div>
  );
};

export default ResumeWebsite;